import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";


const AboutSection = () => {

  const [about, setAbout] = useState([]);

  useEffect(() => {
    fetch(`https://supernewplumbingseowebsite-49ea11fd5bdc.herokuapp.com/about`)
      .then((res) => res.json())
      .then((info) => setAbout(info));
  }, []);





  return (

  

    <>

    {
      about.map(a=><section className="courses_info_section section_space_lg pb-5 mb-5" id="about-sec">
      <div className="container">
        <div className="row align-items-center">
          <div className="col col-lg-6">
            <div className="image_widget">
              <img src={a.aboutImgOne} alt="Collab – Online Learning Platform" />
            </div>
          </div>
          <div className="col col-lg-6">
            <div className="content_wrap ps-lg-3">
              <div className="section_heading">
                <h2 className="heading_text">
                  {a.aboutHeading}
                </h2>
                <p className="heading_description mb-0">
                {a.aboutDetails}
                </p>
              </div>
              <ul className="info_list unordered_list_block">
                <li>
                  <i className="fas fa-square" />
                  <span>
                  {a.aboutPointOne}
                  </span>
                </li>
                <li>
                  <i className="fas fa-square" />
                  <span>
                  {a.aboutPointTwo}
                  </span>
                </li>
                <li>
                  <i className="fas fa-square" />
                  <span>
                  {a.aboutPointThree}
                  </span>
                </li>
              </ul>
              <div className="btn_wrap pb-0">
                <a className="btn btn_dark" href={a.buttonLink}>
                  <span>
                    <small>{a.buttonText}</small>
                    <small>{a.buttonText}</small>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> )
    }



    
    </>
  );
};

export default AboutSection;
