import React, { useEffect, useState } from 'react';

const CounterSection = () => {

  const [countersec, setCounter] = useState([]);

  useEffect(() => {
    fetch(`https://supernewplumbingseowebsite-49ea11fd5bdc.herokuapp.com/counters`)
      .then((res) => res.json())
      .then((info) => setCounter(info));
  }, []);

  return (


    // <>
    // {
    //   countersec.map(e=>  <div className="overflow-hidden mb-5" style={{
    //     backgroundImage:
    //       "url(https://i.postimg.cc/HLTgbfmf/counter-bg-1.jpg)",
    //     backgroundSize: "cover",
    //     backgroundRepeat: "no-repeat",

    //   }}>
    //     <div className="container mb-5">
    //       <div className="counter-sec">
    //         <div className="th-counterup wow fadeInLeft">
    //           <div className="inner">
    //             <div className="content">
    //               <h3 className="counter"><span className="odometer" data-count="2,564">{e.happyClientsNumber}</span>
    //                 <span className="counter-number">+</span>
    //               </h3>
    //               <p className="counter-card_text">{e.happyClientsText}</p>
    //             </div>
    //           </div>
    //         </div>
    //         <div className="th-counterup wow fadeInLeft">
    //           <div className="inner">
    //             <div className="content">
    //               <h3 className="counter"><span className="odometer" data-count={164}>{e.projectCompleteNumber}</span>
    //                 <span className="counter-number">+</span>
    //               </h3>
    //               <p className="counter-card_text">{e.projectCompleteText}</p>
    //             </div>
    //           </div>
    //         </div>
    //         <div className="th-counterup wow fadeInLeft">
    //           <div className="inner">
    //             <div className="content">
    //               <h3 className="counter"><span className="odometer" data-count="2,985">{e.winingAwardNumber}</span>
    //                 <span className="counter-number">+</span>
    //               </h3>
    //               <p className="counter-card_text">{e.winingAwardText}</p>
    //             </div>
    //           </div>
    //         </div>
    //         <div className="th-counterup wow fadeInLeft">
    //           <div className="inner">
    //             <div className="content">
    //               <h3 className="counter"><span className="odometer" data-count={80}>{e.yearofExperienceNumber}</span>
    //                 <span className="counter-number">+</span>
    //               </h3>
    //               <p className="counter-card_text">{e.yearofExperienceText}</p>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>)
    // }



    // </>

    <>

      {
        countersec.map(e =>

          <section className="counter_section bg_light section_space_md">
            <div className="container">
              <div className="row">
                <div className="col col-lg-3 col-md-6">
                  <div className="counter_item">
                    <h3 className="counter_value">
                      <span className="counter_value_text">{e.happyClientsNumber}</span>
                      <span>+</span>
                    </h3>
                    <p className="mb-0">
                    {e.happyClientsText}
                    </p>
                  </div>
                </div>
                <div className="col col-lg-3 col-md-6">
                  <div className="counter_item">
                    <h3 className="counter_value">
                      <span className="counter_value_text">{e.projectCompleteNumber}</span>
                      <span>+</span>
                    </h3>
                    <p className="mb-0">
                    {e.projectCompleteText}
                    </p>
                  </div>
                </div>
                <div className="col col-lg-3 col-md-6">
                  <div className="counter_item">
                    <h3 className="counter_value">
                      <span className="counter_value_text">{e.winingAwardNumber}</span>
                      <span>+</span>
                    </h3>
                    <p className="mb-0">
                    {e.winingAwardText}
                    </p>
                  </div>
                </div>
                <div className="col col-lg-3 col-md-6">
                  <div className="counter_item">
                    <h3 className="counter_value">
                      <span className="counter_value_text">{e.yearofExperienceNumber}</span>
                      <span>+</span>
                    </h3>
                    <p className="mb-0">
                    {e.yearofExperienceText}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>)
      }




    </>
  );
};

export default CounterSection;