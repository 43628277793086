import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import './Banner.css';

const Banner = () => {
  const [banners, setBanners] = useState([]);
  useEffect(() => {
    fetch(`https://supernewplumbingseowebsite-49ea11fd5bdc.herokuapp.com/banner`)
      .then((res) => res.json())
      .then((info) => setBanners(info));
  }, []);



  const handleSubscriber = (event) => {
    event.preventDefault();
    const subscriberEmail = event.target.subscriberEmail.value;

    const sunscribe = {
      subscriberEmail

    };

    const url = `https://supernewplumbingseowebsite-49ea11fd5bdc.herokuapp.com/add-subscriber`;
    fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(sunscribe),
    })
      .then((res) => res.json())
      .then((result) => {

        alert('Thanks For Subscribe..');
      });
  };



  return (



    <>
      {
        banners.map(b => <section className="hero_banner style_2 mouse_move" style={{ backgroundImage: 'url("assets/images/shape/shape_img_6.svg")' }}>
          <div className="container">
            <div className="row align-items-center">
              <div className="col col-lg-6">
                <h1 className="banner_big_title">
                  {b.bannerHeading}
                </h1>
                <p className="banner_description">
                  {b.bannerDetails}
                </p>
                
                <ul className="tags_list unordered_list">
                  <li><a href="#!">Local SEO For Plumber</a></li>
                  <li><a href="#!">Competitor Analysis</a></li>
                  <li><a href="#!">Whitehat SEO</a></li>
                  <li><a href="#!">Keyword Research</a></li>
                  <li><a href="#!">SEO Services</a></li>
                  <li><a href="#!">On Page SEO</a></li>
                  <li><a href="#!">Off Page SEO</a></li>
                  <li><a href="#!">Local SEO</a></li>
                  <li><a href="#!">Plumbing SEO</a></li>
                  
                </ul>
              </div>
              <div className="col col-lg-6 ">
              <div className="layer" data-depth="0.1">
                      <img src={b.imageMain} alt="Collab – Online Learning Platform" />
                    </div>
              </div>
            </div>
          </div>
        </section>)
      }




    </>
  );
};

export default Banner;
