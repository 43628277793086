import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";

const Footer = () => {



  const [footerSocial, setFooterSocial] = useState([]);
  const [footerCopyright, setFooterCopyright] = useState([]);

  const [logo, setLogo] = useState([]);

  useEffect(() => {
    fetch(`https://supernewplumbingseowebsite-49ea11fd5bdc.herokuapp.com/logo`)
      .then((res) => res.json())
      .then((info) => setLogo(info));
  }, []);




  const [footerAddress, setFooterAddress] = useState([]);


  useEffect(() => {
    fetch(`https://supernewplumbingseowebsite-49ea11fd5bdc.herokuapp.com/footer-address`)
      .then((res) => res.json())
      .then((info) => setFooterAddress(info));
  }, [footerAddress]);

  useEffect(() => {
    fetch(`https://supernewplumbingseowebsite-49ea11fd5bdc.herokuapp.com/footer-social`)
      .then((res) => res.json())
      .then((info) => setFooterSocial(info));
  }, []);

  useEffect(() => {
    fetch(`https://supernewplumbingseowebsite-49ea11fd5bdc.herokuapp.com/copyrights`)
      .then((res) => res.json())
      .then((info) => setFooterCopyright(info));
  }, [footerCopyright]);



  const handleSubscriber = (event) => {
    event.preventDefault();
    const subemail = event.target.subemail.value;




    const addItem = {
      subemail,



    };

    const url = `https://supernewplumbingseowebsite-49ea11fd5bdc.herokuapp.com/add-subscriber`;
    fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(addItem),
    })
      .then((res) => res.json())
      .then((result) => {
        Navigate('/all-subscriber');
        alert('Subscribers is Updated');
      });


  }










  return (


    <>
      {
        logo.map(l => <footer className="site_footer">
          <div className="footer_widget_area">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-4">
                  {footerSocial.map(s => (
                    <div className="footer_widget" key={s.id} style={{ padding: '20px' }}>
                      <div className="site_logo">
                        <a className="site_link" href="index.html">
                          <img src={l.logo} alt="Collab - Online Learning Platform" />
                        </a>
                      </div>
                      <p>{s.aboutText}</p>
                      <ul className="social_links unordered_list">
                        <li>
                          <a href={s.fblink}><i className="fab fa-facebook-f" /></a>
                        </li>
                        <li>
                          <a href={s.inslink}><i className="fab fa-instagram" /></a>
                        </li>
                        <li>
                          <a href={s.youlink}><i className="fab fa-youtube" /></a>
                        </li>
                      </ul>
                    </div>
                  ))}
                </div>

                <div className="col-lg-4 col-md-4 col-sm-4">
                  <div className="row">
                    <div className="col-md-6 col-sm-6 d-flex justify-content-center">
                      <div className="footer_widget">
                        <h3 className="footer_widget_title"> Quick Links</h3>
                        <ul className="page_list unordered_list_block">
                          <li>
                            <a href="/">
                              <span className="item_icon"><i className="fas fa-caret-right" /></span>
                              <span className="/">Home</span>
                            </a>
                          </li>
                          <li>
                            <a href="#service-sec">
                              <span className="item_icon"><i className="fas fa-caret-right" /></span>
                              <span className="#service-sec">Service</span>
                            </a>
                          </li>
                          <li>
                            <a href="#about-sec">
                              <span className="item_icon"><i className="fas fa-caret-right" /></span>
                              <span className="#about-sec">About Us</span>
                            </a>
                          </li>
                          <li>
                            <a href="#testi-sec">
                              <span className="item_icon"><i className="fas fa-caret-right" /></span>
                              <span className="#testi-sec">Testimonials</span>
                            </a>
                          </li>
                          <li>
                            <a href="/">
                              <span className="item_icon"><i className="fas fa-caret-right" /></span>
                              <span className="item_text">Contact</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-4">
                  {footerAddress.map(a => (
                    <div className="footer_widget" key={a.id}>
                      <h3 className="footer_widget_title">Contact Us</h3>
                      <div className="iconbox_item contact_info_iconbox">
                        <div className="item_icon">
                          <i className="fas fa-phone"></i>
                        </div>
                        <div className="item_content">
                          <h3 className="item_title text-white">Call Us</h3>
                          <p className="mb-0">{a.phoneNumber}</p>
                        </div>
                      </div>
                      <div className="iconbox_item contact_info_iconbox">
                        <div className="item_icon">
                          <i className="fas fa-envelope"></i>
                        </div>
                        <div className="item_content">
                          <h3 className="item_title text-white">Email Address</h3>
                          <p className="mb-0">{a.emailAddress}</p>
                        </div>
                      </div>
                      <div className="iconbox_item contact_info_iconbox">
                        <div className="item_icon">
                          <i className="fas fa-location-dot"></i>
                        </div>
                        <div className="item_content">
                          <h3 className="item_title text-white">Our Address</h3>
                          <p className="mb-0">{a.location}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="copyright_widget">
            {
              footerCopyright.map(f=><div className="container">
              <p className="copyright_text text-center mb-0">
                <a href="/"></a>{f.copyrightText}
              </p>
            </div>)
            }
            
          </div>
        </footer>)
      }



    </>
  );
};

export default Footer;
