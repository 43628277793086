// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBZH1BF8dEFxAPaOOJigjjQUPjei_Pf6-o",
  authDomain: "newplumbingseoservice.firebaseapp.com",
  projectId: "newplumbingseoservice",
  storageBucket: "newplumbingseoservice.appspot.com",
  messagingSenderId: "782585454103",
  appId: "1:782585454103:web:2e93068dcbe45544704652"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export default auth;