import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const ServicesSection = () => {


  const [items, setItems] = useState([]);
  const [service, setService] = useState([]);

  useEffect(() => {
    fetch(`https://supernewplumbingseowebsite-49ea11fd5bdc.herokuapp.com/service-items`)
      .then((res) => res.json())
      .then((info) => setItems(info));
  }, []);


  useEffect(() => {
    fetch(`https://supernewplumbingseowebsite-49ea11fd5bdc.herokuapp.com/service`)
      .then((res) => res.json())
      .then((info) => setService(info));
  }, []);


  return (

    <>

<section className="courses_section section_space_lg" id="service-sec">
  <div className="container">
    <div className="section_heading">
      <div className="row align-items-center">
        <div className="col col-md-6">
          <h2 className="heading_text mb-0">
            Our Services
          </h2>
        </div>
        
      </div>
    </div>
    <div className="row">
      {
        items.map(s=> <div className="col col-lg-4">
        <div className="course_card style_2">
          <div className="item_image">
            <a href="/" data-cursor-text="View">
              <img src={s.serviceIcon} alt="service Image" />
            </a>
          </div>
          <div className="item_content">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <ul className="item_category_list unordered_list">
                
              </ul>
            
            </div>
           
            <h3 className="item_title">
              <a href="/">
              {s.serviceTitle}
              </a>
            </h3>
            <p>{s.serviceDetails}</p>
          </div>
        </div>
      </div>)
      }
      
      
    
    </div>
    <div className="btn_wrap d-block d-lg-none pb-0 text-center">
      <a className="btn border_dark" href="course.html">
        <span>
          <small>Explore Courses</small>
          <small>Explore Courses</small>
        </span>
      </a>
    </div>
  </div>
</section>
    </>


  );
};

export default ServicesSection;
