import React, { useEffect, useState } from "react";

const TestimonialSection = () => {
  const [testimonial, setTestimonial] = useState([]);

  const [testimonialtext, setTestimonialText] = useState([]);

  useEffect(() => {
    fetch(`https://supernewplumbingseowebsite-49ea11fd5bdc.herokuapp.com/testimonialtext`)
      .then((res) => res.json())
      .then((info) => setTestimonialText(info));
  }, []);

  useEffect(() => {
    fetch(`https://supernewplumbingseowebsite-49ea11fd5bdc.herokuapp.com/testimonials`)
      .then((res) => res.json())
      .then((info) => setTestimonial(info));
  }, []);


  const divStyle = {
    backgroundImage: "url(img/testimonial/test-bg-aliments.png)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundColor: "#fff7f5",
  };

  return (

    <>

    {
      testimonialtext.map(d=> <section className="testimonial_section section_space_lg" id="testi-sec">
      <div className="container">
        <div className="section_heading">
          <div className="row align-items-center">
            <div className="col col-lg-7">
              <h5>
                {d.testimonialText}
              </h5>
              <h2 className="heading_text mb-0">
              {d.testimonialHeading}
              </h2>
            </div>
            <div className="col col-lg-5 d-lg-flex d-none justify-content-end">
              <div className="carousel_arrow">
                <button type="button" className="cc2c_left_arrow">
                  <i className="far fa-arrow-left" />
                  <i className="far fa-arrow-left" />
                </button>
                <button type="button" className="cc2c_right_arrow">
                  <i className="far fa-arrow-right" />
                  <i className="far fa-arrow-right" />
                </button>
              </div>
            </div>
          </div>
        </div>
        {
          testimonial.map(t => <div className="testimonial_carousel">
            <div className="common_carousel_2col" data-cursor-text="Drag" data-slick="{&quot;dots&quot;:false}">
              <div className="carousel_item">
                <div className="testimonial_item">
                  <div className="testimonial_image">
                    <img src={t.image} alt="Collab – Online Learning Platform" />
                  </div>
                  <div className="testimonial_content">
                    <ul className="rating_star unordered_list">
                      <li className="active"><i className="fas fa-star" /></li>
                      <li className="active"><i className="fas fa-star" /></li>
                      <li className="active"><i className="fas fa-star" /></li>
                      <li className="active"><i className="fas fa-star" /></li>
                      <li className="active"><i className="fas fa-star" /></li>
                    </ul>
                    <h3 className="testimonial_title">{t.personName}</h3>
                    <p>
                      {t.desc}
                    </p>

                    <h5 className="testimonial_name">{t.personTitle}</h5>
                    <span className="quote_icon">
                      <i className="fas fa-quote-right" />
                    </span>
                  </div>
                </div>
              </div>



            </div>
            <div className="carousel_arrow d-lg-none d-flex justify-content-center">
              <button type="button" className="cc2c_left_arrow">
                <i className="far fa-arrow-left" />
                <i className="far fa-arrow-left" />
              </button>
              <button type="button" className="cc2c_right_arrow">
                <i className="far fa-arrow-right" />
                <i className="far fa-arrow-right" />
              </button>
            </div>
          </div>)
        }

      </div>
    </section> )
    }

      


    </>



  );
};

export default TestimonialSection;
