import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';


const ContactUs = () => {

  const navigate = useNavigate();
  const [footerAddress, setFooterAddress] = useState([]);

  useEffect(() => {
    fetch(`https://supernewplumbingseowebsite-49ea11fd5bdc.herokuapp.com/footer-address`)
      .then((res) => res.json())
      .then((info) => setFooterAddress(info));
  }, [footerAddress]);





  const handleMessage = (event) => {
    event.preventDefault();
    const name = event.target.name.value;
    const email = event.target.email.value;
    const number = event.target.number.value;
    const subject = event.target.subject.value;
    const message = event.target.message.value;
    const messageStatus = event.target.messageStatus.value;


    const addItem = {
      name,
      email,
      number,
      subject,
      message,
      messageStatus,

    };

    const url = `https://supernewplumbingseowebsite-49ea11fd5bdc.herokuapp.com/add-message`;
    fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(addItem),
    })
      .then((res) => res.json())
      .then((result) => {
        navigate('/thanks');
        alert('Message is Updated');
      });
  };

  const [action, setAction] = useState([]);

  useEffect(() => {
    fetch(`https://supernewplumbingseowebsite-49ea11fd5bdc.herokuapp.com/actions`)
      .then((res) => res.json())
      .then((info) => setAction(info));
  }, []);



  return (



    <>
   
  

<section className="contact_form_section section_space_lg bg_light decoration_wrap overflow-hidden" id='contact-sec'>
  <div className="container decoration_wrap">
    <div className="row justify-content-center">
      <div className="col col-lg-7">
        <div className="section_heading text-center">
          <h2 className="heading_text mb-0">
            Contact Our Support Team to Know What You Want
          </h2>
        </div>
      </div>
    </div>
    <form action="#" onSubmit={handleMessage}>
      <div className="row justify-content-center">
        <div className="col col-lg-8">
          <div className="row">
            <div className="col col-md-6">
              <div className="form_item m-0">
                <label htmlFor="input_name" className="input_title">Name</label>
                <input id="input_name" type="text" name="name" placeholder="Your Name" />
              </div>
            </div>
            <div className="col col-md-6">
              <div className="form_item m-0">
                <label htmlFor="input_email" className="input_title">Email</label>
                <input id="input_email" type="email" name="email" placeholder="Your Email" />
              </div>
            </div>
            <div className="col col-md-6">
              <div className="form_item m-0">
                <label htmlFor="input_phone" className="input_title">Phone</label>
                <input id="input_phone" type="tel" name="number" placeholder="Your Phone" />
              </div>
            </div>
            <div className="col col-md-6">
              <div className="form_item m-0">
                <label htmlFor="input_jubject" className="input_title">Subject</label>
                <input id="input_phone" type="tel" name="subject" placeholder="Your Subject" />
                <input id="input_phone" type="tel" name="messageStatus"  value="UnRead" hidden placeholder="Your Subject" />
                
              </div>
            </div>
            <div className="col">
              <div className="form_item">
                <label htmlFor="input_message" className="input_title">Message</label>
                <textarea id="input_message" name="message" placeholder="Type Your Message" defaultValue={""} />
              </div>
              <button type="submit" className="btn btn_dark w-100 b-block">
                <span>
                  <small>Send Your Message</small>
                  <small>Send Your Message</small>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div className="deco_item shape_img_1 wow fadeInUp" data-wow-delay=".2s">
      <img src="assets/images/shape/shape_img_7.png" alt="Collab – Online Learning Platform" />
    </div>
    <div className="deco_item shape_img_2 wow fadeInUp" data-wow-delay=".4s">
      <img src="assets/images/shape/shape_img_7.png" alt="Collab – Online Learning Platform" />
    </div>
  </div>
  <div className="deco_item shape_img_3 wow fadeInLeft" data-wow-delay=".2s">
    <img src="assets/images/shape/shape_img_7.png" alt="Collab – Online Learning Platform" />
  </div>
  <div className="deco_item shape_img_4 wow fadeInRight" data-wow-delay=".4s">
    <img src="assets/images/shape/shape_img_7.png" alt="Collab – Online Learning Platform" />
  </div>
</section>



    </>
  );
};

export default ContactUs;