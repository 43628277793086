import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const PricingSection = () => {

  const [pricing, setPricing] = useState([]);


  useEffect(() => {
    fetch(`https://supernewplumbingseowebsite-49ea11fd5bdc.herokuapp.com/pricings`)
      .then((res) => res.json())
      .then((info) => setPricing(info));
  }, []);

  return (


    <>
      {/* <section className="pricing-section pricing-section-line section-gap bg-color-off-white">
        <div className="container">
          <div className="section-heading text-center mb-50">
            <h2 className="title">Best Pricing For Business</h2>
            <span className="tagline">Best SEO Optimization Agency</span>
          </div>
          <div className="row no-gutters align-items-center justify-content-center">
            {
              pricing.map(b => b.packageName === 'Basic' &&

                <div className="col-lg-4 col-md-7 col-sm-8">
                  <div className="pricing-table pricing-table-two">
                    <h4 className="plan-name">{b.packageName}</h4>
                    <p>
                      {b.packageDetails}
                    </p>
                    <div className="plan-price">
                      <span className="currency">$</span>
                      <span className="price">{b.packagePrice}</span>
                    </div>
                    <Link to={`/order-now/${b._id}`} className="btn btn_dark  px-4 py-3 bordered-btn">
                      {b.packageButtonText} <i className="far fa-long-arrow-right" />
                    </Link>

                    <ul className="feature-list">
                      <li><i className="far fa-check" /> {b.packagePointOne}</li>
                      <li><i className="far fa-check" /> {b.packagePointTwo}</li>
                      <li><i className="far fa-check" /> {b.packagePointThree}</li>
                      <li><i className="far fa-check" /> {b.packagePointFour}</li>
                      <li><i className="far fa-check" /> {b.packagePointFive}</li>
                      <li><i className="far fa-check" /> {b.packagePointSix}</li>
                      <li><i className="far fa-check" /> {b.packagePointSeven}</li>

                    </ul>
                  </div>
                </div>)
            }

            {
              pricing.map(s => s.packageName === 'Standard' &&

                <div className="col-lg-4 col-md-8 col-sm-9">
                  <div className="pricing-table pricing-table-two featured-plan">
                    <h4 className="plan-name">{s.packageName}</h4>
                    <p>
                      {s.packageDetails}
                    </p>
                    <div className="plan-price">
                      <span className="currency">$</span>
                      <span className="price"> {s.packagePrice}</span>
                    </div>
                    <Link to={`/order-now/${s._id}`} className="btn btn_dark  px-4 py-3 bordered-btn">
                      {s.packageButtonText} <i className="far fa-long-arrow-right" />
                    </Link>

                    <ul className="feature-list">
                      <li><i className="far fa-check" /> {s.packagePointOne}</li>
                      <li><i className="far fa-check" /> {s.packagePointTwo}</li>
                      <li><i className="far fa-check" /> {s.packagePointThree}</li>
                      <li><i className="far fa-check" /> {s.packagePointFour}</li>
                      <li><i className="far fa-check" /> {s.packagePointFive}</li>
                      <li><i className="far fa-check" /> {s.packagePointSix}</li>
                      <li><i className="far fa-check" /> {s.packagePointSeven}</li>
                      <li><i className="far fa-check" /> {s.packagePointEight}</li>
                      <li><i className="far fa-check" /> {s.packagePointNine}</li>
                      <li><i className="far fa-check" /> {s.packagePointTen}</li>
                    </ul>
                    <span className="featured-label">
                      Popular
                    </span>
                  </div>
                </div>)
            }

            

          </div>
        </div>
      </section> */}


      <section className="pricing_section section_space_lg pb-0">
        <div className="container decoration_wrap">
          <div className="section_heading text-center">
            <h2 className="heading_text mb-0">
              Premium Price Packages
            </h2>
          </div>
          <div className="pricing_cards_wrapper row align-items-center">

            {
              pricing.map(b => b.packageName === 'Basic' && <div className="col col-lg-4">
                <div className="pricing_card text-center tilt">
                  <h3 className="card_heading">{b.packageName} Plan</h3>
                  <div className="pricing_wrap">
                    <span className="price_value"><sup>$</sup>{b.packagePrice}</span>

                  </div>
                  <hr />
                  <ul className="info_list unordered_list_block text-start">
                    <li>
                      <i className="fas fa-caret-right" />
                      <span>{b.packagePointOne}</span>
                    </li>
                    <li>
                      <i className="fas fa-caret-right" />
                      <span>{b.packagePointTwo}</span>
                    </li>
                    <li>
                      <i className="fas fa-caret-right" />
                      <span>{b.packagePointThree}</span>
                    </li>
                    <li>
                      <i className="fas fa-caret-right" />
                      <span>{b.packagePointFour}</span>
                    </li>
                    <li>
                      <i className="fas fa-caret-right" />
                      <span>{b.packagePointFive}</span>
                    </li>
                    <li>
                      <i className="fas fa-caret-right" />
                      <span>{b.packagePointSix}</span>
                    </li>
                    <li>
                      <i className="fas fa-caret-right" />
                      <span>{b.packagePointSeven}</span>
                    </li>
                  </ul>
                  <div className="btn_wrap pb-0">
                    <Link className="btn border_dark" to={`/order-now/${b._id}`} >

                      <span>
                        <small>{b.packageButtonText} </small>
                        <small>{b.packageButtonText} </small>


                      </span>
                    </Link>
                  </div>
                </div>
              </div>)
            }

            {

              pricing.map(p => p.packageName === 'Premium' && <div className="col col-lg-4">
                <div className="pricing_card text-center bg_dark tilt">
                  <div className="card_badge">recommended</div>
                  <h3 className="card_heading">{p.packageName} Plan</h3>
                  <div className="pricing_wrap">
                    <span className="price_value"><sup>$</sup>{p.packagePrice}</span>

                  </div>
                  <hr />
                  <ul className="info_list unordered_list_block text-start">
                    <li>
                      <i className="fas fa-caret-right" />
                      <span>{p.packagePointOne}</span>
                    </li>
                    <li>
                      <i className="fas fa-caret-right" />
                      <span>{p.packagePointTwo}</span>
                    </li>
                    <li>
                      <i className="fas fa-caret-right" />
                      <span>{p.packagePointThree}</span>
                    </li>
                    <li>
                      <i className="fas fa-caret-right" />
                      <span>{p.packagePointFour}</span>
                    </li>
                    <li>
                      <i className="fas fa-caret-right" />
                      <span>{p.packagePointFive}</span>
                    </li>
                    <li>
                      <i className="fas fa-caret-right" />
                      <span>{p.packagePointSix}</span>
                    </li>
                    <li>
                      <i className="fas fa-caret-right" />
                      <span>{p.packagePointSeven}</span>
                    </li>
                    <li>
                      <i className="fas fa-caret-right" />
                      <span>{p.packagePointEight}</span>
                    </li>
                  </ul>
                  <div className="btn_wrap pb-0">


                    <Link className="btn btn_primary" to={`/order-now/${p._id}`} >

                      <span>
                        <small>{p.packageButtonText} </small>
                        <small>{p.packageButtonText} </small>


                      </span>
                    </Link>
                  </div>
                </div>
              </div>)
            }


            {
              pricing.map(s => s.packageName === 'Standard' &&
                <div className="col col-lg-4">
                  <div className="pricing_card text-center tilt">
                    <h3 className="card_heading">{s.packageName} Plan</h3>
                    <div className="pricing_wrap">
                      <span className="price_value"><sup>$</sup>{s.packagePrice}</span>

                    </div>
                    <hr />
                    <ul className="info_list unordered_list_block text-start">
                      <li>
                        <i className="fas fa-caret-right" />
                        <span>{s.packagePointOne}</span>
                      </li>
                      <li>
                        <i className="fas fa-caret-right" />
                        <span>{s.packagePointTwo}</span>
                      </li>
                      <li>
                        <i className="fas fa-caret-right" />
                        <span>{s.packagePointThree}</span>
                      </li>
                      <li>
                        <i className="fas fa-caret-right" />
                        <span>{s.packagePointFour}</span>
                      </li>
                      <li>
                        <i className="fas fa-caret-right" />
                        <span>{s.packagePointFive}</span>
                      </li>
                      <li>
                        <i className="fas fa-caret-right" />
                        <span>{s.packagePointSix}</span>
                      </li>
                      <li>
                        <i className="fas fa-caret-right" />
                        <span>{s.packagePointSeven}</span>
                      </li>
                    </ul>
                    <div className="btn_wrap pb-0">
                      <Link className="btn border_dark" to={`/order-now/${s._id}`} >

                        <span>
                          <small>{s.packageButtonText} </small>
                          <small>{s.packageButtonText} </small>


                        </span>
                      </Link>
                    </div>
                  </div>
                </div>)
            }



          </div>
          <div className="deco_item shape_img_1" data-parallax="{&quot;y&quot; : 130, &quot;smoothness&quot;: 6}">
            <img src="assets/images/shape/shape_img_4.png" alt="Collab – Online Learning Platform" />
          </div>
          <div className="deco_item shape_img_2" data-parallax="{&quot;y&quot; : -130, &quot;smoothness&quot;: 6}">
            <img src="assets/images/shape/shape_img_5.png" alt="Collab – Online Learning Platform" />
          </div>
        </div>
      </section>





    </>
  );
};

export default PricingSection;