import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { useAuthState } from "react-firebase-hooks/auth";
import auth from "../../firebase.init";
import { signOut } from "firebase/auth";



const NavBar = () => {

  const [user] = useAuthState(auth);
  const handleSignOut = () => {
    signOut(auth);
  }
  const [users, setUser] = useState([]);
  const [logo, setLogo] = useState([]);

  useEffect(() => {
    fetch(`https://supernewplumbingseowebsite-49ea11fd5bdc.herokuapp.com/logo`)
      .then((res) => res.json())
      .then((info) => setLogo(info));
  }, []);


  useEffect(() => {
    fetch(`https://supernewplumbingseowebsite-49ea11fd5bdc.herokuapp.com/users`)
      .then((res) => res.json())
      .then((info) => setUser(info));
  }, []);




  return (


    <>
      <header className="site_header site_header_2">
        <div className="container">
          <div className="row align-items-center">
            <div className="col col-lg-3 col-5">
              {
                logo.map(l=>    <div className="site_logo">
                <a className="site_link" href="/">
                  <img src={l.logo} alt="Logo" />
                </a>
              </div> )
              }
          


            </div>
            <div className="col col-lg-6 col-2">
              <nav className="main_menu navbar navbar-expand-lg">
                <div className="main_menu_inner collapse navbar-collapse justify-content-center" id="main_menu_dropdown">
                  <ul className="main_menu_list unordered_list_center">




                    <li><a className="nav-link" href="/">Home</a></li>
                    <li><a className="nav-link" href="#service-sec">Services</a></li>
                    <li><a className="nav-link" href="#about-sec">About Us</a></li>
                    <li><a className="nav-link" href="#testi-sec">Testimonial</a></li>
                    <li><a className="nav-link" href="#contact-sec">Contact Us</a></li>

                    

                  </ul>
                </div>
              </nav>
            </div>
            <div className="col col-lg-3 col-5">
              <ul className="header_btns_group unordered_list_end">
                <li>
                  <button className="mobile_menu_btn" type="button" data-bs-toggle="collapse" data-bs-target="#main_menu_dropdown" aria-controls="main_menu_dropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <i className="far fa-bars" />
                  </button>
                </li>
                <li>
                  <a className="" href="login.html">
                    <span>
                    {
                      user?.email ?
                        <li>
                          <Link to="/dashboard" class="btn btn_dark  px-4 py-3" data-animation="fadeInRight" data-delay=".8s">Dashboard</Link>

                        </li>
                        :
                        <li><Link to="/login" className="btn btn_dark  px-4 py-3">
                          <i className="envy envy-user" />
                          Log in
                        </Link></li>



                    }

                    
                    </span>
                  </a>
                </li>
                <li>
                  <a className="" >
                    <span>
                    {
                      users.map(u => u.userEmail === user?.email && u.userRole === 'Admin' &&

                        <li>  <Link to="/admin" className="btn btn_dark  px-4 py-3">

                          Admin
                        </Link></li>

                      )

                    }
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>


    </>
  );
};

export default NavBar;
